<template>
  <div>
    <v-card rounded="lg">
      <v-card-title class="headline font-weight-black">
        {{ experience.title }}
      </v-card-title>
      <v-card-text>
        <div>
          Pastor name: <span class="font-weight-bold">{{ experience.pastor_name }}</span>
        </div>
        <div>
          Location: <span class="font-weight-bold">{{ experience.location ? experience.location.name : '' }}</span>
        </div>
        <div>
          Attendance count: <span class="font-weight-bold">{{ experience.attendance ? experience.attendance.length : 0 }}</span>
        </div>
        <div>
          Start at: <span class="font-weight-bold">{{ experience.start_at | MIXINS_UTC_TO_LOCAL_TIMESTAMPS }}</span>
        </div>
        <div>
          End at: <span class="font-weight-bold">{{ experience.end_at | MIXINS_UTC_TO_LOCAL_TIMESTAMPS }}</span>
        </div>
        <div>
          <div>Parts:</div>
          <div v-if="experience.parts">
            <ul>
              <li
                v-for="(part, key) in experience.parts"
                :key="key"
                class="font-weight-bold"
              >
                <v-icon v-if="part.type == 'user_note'">mdi-comment-account-outline</v-icon>
                <v-icon v-if="part.type == 'poll'">mdi-poll</v-icon>
                <v-icon v-if="part.type == 'note'">mdi-note-outline</v-icon>
                <v-icon v-if="part.type == 'quote'">mdi-format-quote-close</v-icon>
                <v-icon v-if="part.type == 'image'">mdi-file-image</v-icon>
                {{ part.type }}
              </li>
            </ul>
          </div>
        </div>
        <div>
          Created at: <span class="font-weight-bold">{{ experience.created_at | MIXINS_UTC_TO_LOCAL_TIMESTAMPS }}</span>
        </div>
        <div>
          Updated at: <span class="font-weight-bold">{{ experience.updated_at | MIXINS_UTC_TO_LOCAL_TIMESTAMPS }}</span>
        </div>
      </v-card-text>
    </v-card>
    <v-card rounded="lg" flat class="mt-5">
      <v-card-title> Attendance </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="experience.attendance"
          dense
          hide-default-footer
        >
          <template v-slot:[`item.name`]="{ item }">
            {{ item.user.first_name }} {{ item.user.last_name }}
          </template>
          <template v-slot:[`item.email`]="{ item }">
            {{ item.user.email }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    experience: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data: () => ({
    headers: [
      {
        text: "Name",
        value: "name",
        sortable: false,
      },
      {
        text: "Email",
        value: "email",
        sortable: false,
      }
    ]
  })
}
</script>